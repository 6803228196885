@import "../../variables.scss";

.privacy-policy {
  padding: 25px 65px;
  line-height: 24px;

  &__logo {
    margin-bottom: 30px;
  }

  h1 {
    line-height: 35px;
  }

  sup {
    font-size: 10px;
  }

  hr {
    margin: 50px 0px 10px 0px;
    width: 35%;
  }

  .explanation {
    font-size: 12px;
    line-height: 16px;
  }

  h4 {
    font-size: 18px;
  }

  table {
    margin-top: 20px;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #ddd;
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .privacy-policy {
    padding: 25px 20px;
  }
}
