.education-page {
  padding: 0 60px 72px;
  &__logo {
    margin: 30px 0 35px 0;
  }

  &__container {
    max-width: 868px;
    margin: 0 auto;

  }

  h1 {
    font-size: 32px;
    margin-bottom: 57px;
  }

  &__instruction {
    background-color: #f5f5f5;
    padding: 33px 29px 28px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;

    & > div {
      margin-bottom: 21px;
      font-size: 18px;
      font-weight: 600;
    }

    li {
      margin-bottom: 12px;
      font-size: 16px;
    }

    span {
      font-weight: 700;
    }
  }

  .start-btn-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
    .start-education-btn {
      min-width: 65px;
      max-width: 100px;
      font-size: 14px;
      height: 45px;
    }
  }
}
